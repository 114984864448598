body {
  margin: 0;
  font-family: 'Helvetica', 'Poppins', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: sans-serif, source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
}

#customTable .custom-table-responsive {
  max-height: 28vh !important;
  width: 100% !important;
  overflow-x: scroll;
}
.table-responsive {
  max-height: 90vh;
  /* max-width: 90vw; */
}

/* .table-responsive {
  max-height: 22vh;
} */

#customModal .modal-content {
  width: 396px; /* Specify the width */
  border-radius: 50px; /* Specify the border radius */
  padding-bottom: 20px;
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
