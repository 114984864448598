/* styles.css */

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
  margin-top: 20px;
}

.pagination > li {
  margin: 0;
}

.pagination > li > a {
  text-decoration: none;
  cursor: pointer;
  padding: 3px 6px;
  border: 1px solid #007bff;
  color: #007bff;
  background-color: white;
}

.pagination > li.active > a {
  background-color: #007bff;
  color: black;
  border-color: #007bff;
}

.pagination > li.disabled > a {
  cursor: not-allowed;
  opacity: 0.6;
}

.pagination > li > a:focus,
.pagination > li > a:hover {
  background-color: #007bff;
}
