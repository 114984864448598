*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

.container {
  position: relative;
  height: 160px;
  width: 160px;
  /* background-color: #ffffff; */
  border-radius: 10px;
}

.toggleSwitch {
  appearance: none;
  -webkit-appearance: none;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  height: 50px;
  width: 100px;
  background-color: #f2f2f2;
  border-radius: 50px;
  cursor: pointer;
  transition: 0.5s;
}
label[for='cup'] {
  position: absolute;
  background-color: #cccccc;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 30.5px;
  height: 50px;
  width: 50px;
  border: 10px solid #e7e7e7;
  border-radius: 50%;
  box-shadow: inset -6px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: 0.5s;
}
label[for='cup']:before {
  content: '';
  position: absolute;
  height: 10px;
  width: 25px;
  background-image: linear-gradient(to left, #bebebe 30%, #cccccc 30%);
  margin: auto;
  top: 0;
  bottom: 0;
  left: -32px;
}
.toggleSwitch:checked {
  background-color: #fab142;
}
.toggleSwitch:checked + label[for='cup'] {
  transform: translateX(50px) rotate(180deg);
  background-color: #6e4c42;
  box-shadow: inset 6px -6px rgba(0, 0, 0, 0.2);
}
