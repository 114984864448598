/*custom font*/
@import url(https://fonts.googleapis.com/css?family=Merriweather+Sans);

* {
  margin: 0;
  padding: 0;
}

.breadcrumb {
  display: inline-block;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.35);
  overflow: hidden;
  border-radius: 5px;
  counter-reset: flag;
  cursor: pointer;
  margin-bottom: 0;
}

.breadcrumb a {
  text-decoration: none;
  outline: none;
  display: block;
  float: left;
  font-size: 16px;
  line-height: 36px;
  color: white;
  padding: 0 10px 0 30px;
  background: #666;
  background: linear-gradient(#666, #333);
  position: relative;
}
.breadcrumb a:first-child {
  padding-left: 20px;
  border-radius: 5px 0 0 5px;
}
.breadcrumb a:first-child:before {
  left: 14px;
}
.breadcrumb a:last-child {
  border-radius: 0 5px 5px 0;
  padding-right: 20px;
  background: #3a3c1c;
  color: #fff;
}

.breadcrumb a.active,
.breadcrumb a:hover {
  background: #333;
  background: linear-gradient(#333, #000);
}
.breadcrumb a.active:after,
.breadcrumb a:hover:after {
  background: #333;
  background: linear-gradient(135deg, #333, #000);
}

.breadcrumb a:after {
  content: "";
  position: absolute;
  top: 0;
  right: -18px;
  width: 36px;
  height: 36px;
  transform: scale(0.707) rotate(45deg);
  z-index: 1;
  background: #666;
  background: linear-gradient(135deg, #666, #333);
  box-shadow: 2px -2px 0 2px rgba(0, 0, 0, 0.4),
    3px -3px 0 2px rgba(255, 255, 255, 0.1);
  border-radius: 0 5px 0 50px;
}
.breadcrumb a:last-child:after {
  content: none;
}

.flat a,
.flat a:after {
  background: white;
  color: black;
  transition: all 0.5s;
}
.flat a:before {
  background: white;
  box-shadow: 0 0 0 1px #ccc;
}
.flat a:hover,
.flat a.active,
.flat a:hover:after,
.flat a.active:after {
  background: #3a3c1c;
}
