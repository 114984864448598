/* styles.css */

/* Customizing the pill appearance */
.nav-pills .nav-link {
  background-color: #fff;
  color: #ffc107; /* White color for the pill text */
  font-size: 14px; /* Custom font size */
  padding: 10px 20px; /* Custom padding */
}

/* Customizing the active pill appearance */
.nav-item :active {
  color: #ffc107;
}

.nav-pills .nav-link.active {
  background-color: orange;
  font-weight: 700px;
  border-radius: 0px;
  border-Bottom: 2px solid #000;
  transition: width 2s;
}