@media print {
  @page {
    /* size: 210mm 297mm; */
    /* size: landscape; */
  }
  body {
    font-size: 75%; /* Adjust the font size as needed */
  }
  table {
    width: 100%; /* Set the width to 100% to make it fit within the page */
  }
  .custom-print-table {
    width: 99%;
    align-self: center;
    margin-left: 5px;
    margin-right: 5px;
  }
  .custom-print-table th,
  .custom-print-table td {
    border: 1px solid black !important; /* Adjust the border style as needed */
    vertical-align: middle;
  }
}
